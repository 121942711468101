// override bootstrap font sizing as it is calculated


.smallcaps{
    font-variant: small-caps;
}

.dropcap::first-letter{
    background-color: transparent;
    color: $primary ;
    float: left;
    font: normal normal 3.5em/.8em serif;
    margin: 0 .083em 0 0;
    padding: .0625em 0 0;
    text-shadow: 3px, 2px, 2px, rgba(0,0,0,0.5);
}

.dropcaps::first-letter {
    float: left;
    color: $primary;
    font-size: 3em;
    font-weight: 400;
    padding: 10px 10px 10px 5px;
    margin-top: 2px;
}

.dropcaps-bg::first-letter {
    background-color: $primary;
    color: #fff;
    padding-bottom: 12px;
    margin-right: 10px;
    border-radius: 2px;
    padding: 10px 5px;
    font-size: 2.8em;
    margin-top: 4px;
}

.dropcaps-circle::first-letter {
    border: solid 1px $primary;
    border-radius: 30px;
    padding: 10px 8px;
    font-size: 2.3em;
    margin-right: 10px;
    margin-top: 2px;
}

.dropcaps-circle-bg::first-letter {
    background-color: $primary;
    color: #fff;
    border-radius: 30px;
    padding: 10px 8px;
    font-size: 2.3em;
    margin-right: 10px;
    margin-top: 2px;
}

