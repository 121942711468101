$print-font-size: 12pt;
@if $enable-print-styles {
    @media print {
        body {
        font: $print-font-size Georgia, "Times New Roman", Times, serif;
        line-height: 1.3;
        background: #fff !important;
        color: #000;
        }
        h1, .h1 { font-size: ($print-font-size * 1.625); }
        h2, .h2 { font-size: ($print-font-size * 1.5); }
        h3, .h3 { font-size: ($print-font-size * 1.375); }
        h4, .h4 { font-size: ($print-font-size * 1.25); }
        h5, .h5 { font-size: ($print-font-size * 1.125); }
        h6, .h6 { font-size: ($print-font-size * 1); }

        .lead{ font-size: ($print-font-size * 1.125); }

        #demoSelect,
        #back-top {
            display: none;
        }

        .container {
            width: auto;
        }

        .card-columns {
            column-count: 2 ;
        }
    }
}
