/*! extras
* @Author: Jeff Shields
* @Date:   2019-01-20 07:34:24
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-03-27 13:09:00
*/

$use-ribbon: true !default;
$use-mobi: false !default;
$use-audio: true !default;
$use-back-to-top: true !default;

// for google maps display
$google-maps-width: 98% ;
$google-maps-height: 19rem ;
$google-maps-height--large: 25rem;

// number of cards per line for each breakpoint
$cards-per-line: (
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 3
);


// common to all themes
@import "project/setup";

// load the bootstrap functions, bs4 variables & mixins,
@import "project/bs4-required";

// load the extras
@import "project/load-extras";
