
.blockquote-quotemark{
    padding-left: 2em;
    border-left: 0 solid $primary;

    &:before { display: block;
        height: 0;
        content: "“";
        margin-left: -.5em;
        font: italic 4em/.75 Cochin, Georgia, "Times New Roman", serif;
        color: $info;
    }
}

.blockquote-color {
    border-color: $primary;
}

.blockquote-color-bg {
    border-color: $secondary;
    border-bottom: solid 1px $primary;
    background-color: $secondary;

}

.blockquote-big {
    font-size: 1.8em;
    line-height: 140%;
    font-style: oblique;
    border: none;
}

.blockquote-big footer {
    font-style: normal;
    font-size: .7em;
}

.blockquote-color-bg-primary {
    font-size: 1em;
    background-color: $primary;
    color: #fff;
    padding: 20px;
    text-align: center;
    position: relative;
    border: none;
}

.blockquote-color-bg-primary:after,
.blockquote-color-bg-dark:after {
    font-family: "FontAwesome";
    content: "\f10e";
    position: absolute;
    left: 10px;
    bottom: 10px;
    color: #fff;
    font-size: 2.6em;
}

.blockquote-color-bg-dark {
    font-size: 1em;
    background-color: #444;
    color: #fff;
    padding: 20px;
    text-align: center;
    position: relative;
    border: none;
}

.blockquote-color-bg-light {
    background-color: #ddd;
    border: none;
    color: #333;
    font-size: 1em;
    padding: 20px;
    position: relative;
    text-align: center;
    text-shadow: 1px 1px 0 rgb(255, 255, 255);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
}

.blockquote-color-bg-light:after {
    font-family: "FontAwesome";
    content: "\f10e"; // fa-quote-right
    position: absolute;
    left: 10px;
    bottom: 10px;
    color: #333;
    font-size: 2.6em;
}

.blockquote-color-bg-primary footer,
.blockquote-color-bg-dark footer {
    color: #eee;
    font-size: .9em;
}

.blockquote-color-bg-light footer {
    color: #666;
    font-size: .9em;
}
