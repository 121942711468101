.logo {
    margin-right: auto;
    margin-left: auto;
    display: block;

    @include media-breakpoint-up(lg) {
        margin-right: auto;
        margin-left: 0;
    }

}
