/*
* @Author: Jeff Shields
* @Date:   2019-01-29 10:24:25
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-01-29 10:29:13
*/


@if $use-ribbon {
    .box {
      // width: 100%;
      position: relative;
      border: 0 solid #BBB;
      background: transparent;
    }
    .ribbon {
      position: absolute;
      left: -5px;
      top: -5px;
      z-index: 1;
      overflow: hidden;
      width: 100px;
      height: 100px;
      text-align: right;
    }
    .ribbon span {
      font-size: 10px;
      font-weight: bold;
      color: #FFF;
      text-transform: uppercase;
      text-align: center;
      line-height: 20px;
      transform: rotate(-45deg);
      width: 100px;
      display: block;

      box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      position: absolute;
      top: 19px; left: -21px;
    }
    .ribbon span::before {
      content: "";
      position: absolute; left: 0; top: 100%;
      z-index: -1;

      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;

    }
    .ribbon span::after {
      content: "";
      position: absolute; right: 0; top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-bottom: 3px solid transparent;
    }


    .ribbon.ribbon-success span{
        background: $success;
        background: linear-gradient(darken($success, 10%) 0%, $success 100%);
    }
    .ribbon.ribbon-success span::before {
        border-left: 3px solid $success;
        border-top: 3px solid $success;
    }
    .ribbon.ribbon-success span::after{
        border-right: 3px solid $success;
        border-top: 3px solid $success;
    }
    .ribbon.ribbon-danger span{
        background: $danger;
        background: linear-gradient(darken($danger, 10%) 0%, $danger 100%);
    }
    .ribbon.ribbon-danger span::before {
        border-left: 3px solid $danger;
        border-top: 3px solid $danger;
    }
    .ribbon.ribbon-danger span::after{
        border-right: 3px solid $danger;
        border-top: 3px solid $danger;
    }
}
