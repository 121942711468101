/*
* @Author: Jeff Shields
* @Date:   2018-03-27 15:33:23
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-03-27 11:52:34
*/


.google-maps{
    width: $google-maps-width;
    height: $google-maps-height;
    margin: 0 auto 1rem;
}

.google-maps--large{
    height: $google-maps-height--large;
}

.google-map iframe {
    height: 100%;
    width: 100%;
    // min-height: 300px;
}
