/*
* @Author: Jeff Shields
* @Date:   2019-01-29 09:33:13
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-01-29 10:08:00
*/

.separator{
    .list-inline-item + .list-inline-item {
      border-left: 1px solid currentColor;
      padding-left: .5rem;
    }
}
